import React from 'react';
import styled from 'styled-components';

export const InputField = ({label, type, id, value, handleChange, options}) => {
  return (
    <FieldContainer>
      <FieldLabel>{label}</FieldLabel>
      {(() => {
        switch(type) {
          case 'text': 
            return <TextInput id={id} value={value} onChange={handleChange}/>;
          case 'dropdown':
            return (
              <DropdownInput
                id={id}
                value={value}
                onChange={handleChange}
              >
                {options.map(option => (
                    <option value={option} onChange={handleChange}>{option}</option>
                ))}
              </DropdownInput>
            );
          default:
            return <TextInput/>
        }}
      )()
    }
    </FieldContainer>
  )
}

const FieldContainer = styled.div`
  width: 146px;
  margin: 0px 0px 0px 0px;
  padding: 0px 12px 0px 12px;
`;

const FieldLabel = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.31px;
  text-align: left;
  margin: 0px 0px 0px 0px;
`;

const TextInput = styled.input`
  width: 100%;
  height: 30px;
  border-radius: 7px;
  border: 1px solid #979797;
`;

const DropdownInput = styled.select`
  width: 100%;
  height: 30px;
  border-radius: 7px;
  border: 1px solid #979797;
`;