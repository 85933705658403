import {s3Download, s3LogoUpload} from '../libs/awsLib';
import { API } from 'aws-amplify';

// Create mockup generation function
  export const generateMockup = async (generateMockupState) => {
    const projectName = generateMockupState.projectName
    const brand       = generateMockupState.brand
    const productCode = generateMockupState.productCode
    const color       = generateMockupState.color
    const runFront    = (generateMockupState.frontLocation && generateMockupState.frontLogoFile)
    const runBack     = (generateMockupState.backLocation  && generateMockupState.backLogoFile)

    const switchFns = {
      'generateFront' : async () => {
        const file     = generateMockupState.frontLogoFile
        const fileName = generateMockupState.frontLogoFileName
        const params   = {
          body: {
            projectName  : projectName,
            brand        : brand,
            productCode  : productCode,
            color        : color,
            location     : generateMockupState.frontLocation,
            logoFileName : generateMockupState.frontLogoFileName,
            removeBG     : generateMockupState.removeFrontBG
          }
        }
        await s3LogoUpload(projectName, file, fileName)
        const fileNameOutput = await API.put('blueprint-mockup-api', '/generateMockup2', params)
        console.log(fileNameOutput)
        await s3Download(projectName, fileNameOutput)
      },
      'generateBack' : async () => {
        const file     = generateMockupState.backLogoFile
        const fileName = generateMockupState.backLogoFileName
        const params   = {
          body: {
            projectName  : projectName,
            brand        : brand,
            productCode  : productCode,
            color        : color,
            location     : generateMockupState.backLocation,
            logoFileName : generateMockupState.backLogoFileName,
            removeBG     : generateMockupState.removeBackBG
          }
        }
        await s3LogoUpload(projectName, file, fileName)
        const fileNameOutput = await API.put('blueprint-mockup-api', '/generateMockup2', params)
        console.log(fileNameOutput)
        await s3Download(projectName, fileNameOutput)
      },
      'generateAll' : async () => {
        // Create Front parameters
        const frontFile     = generateMockupState.frontLogoFile
        const frontFileName = generateMockupState.frontLogoFileName
        const frontParams   = {
          body: {
            projectName  : projectName,
            brand        : brand,
            productCode  : productCode,
            color        : color,
            location     : generateMockupState.frontLocation,
            logoFileName : generateMockupState.frontLogoFileName,
            removeBG     : generateMockupState.removeFrontBG
          }
        }
        // Create Back parameters
        const backFile     = generateMockupState.backLogoFile
        const backFileName = generateMockupState.backLogoFileName
        const backParams   = {
          body: {
            projectName  : projectName,
            brand        : brand,
            productCode  : productCode,
            color        : color,
            location     : generateMockupState.backLocation,
            logoFileName : generateMockupState.backLogoFileName,
            removeBG     : generateMockupState.removeBackBG
          }
        }
        await Promise.all([s3LogoUpload(projectName,frontFile, frontFileName),
                          s3LogoUpload(projectName, backFile, backFileName)])

        const [frontFileNameOutput, backFileNameOutput] = await Promise.all([
          API.put('blueprint-mockup-api', '/generateMockup2', frontParams),
          API.put('blueprint-mockup-api', '/generateMockup2', backParams)])

        await Promise.all([s3Download(projectName, frontFileNameOutput),
                          s3Download(projectName, backFileNameOutput)])
      }
    }

    if (runFront && runBack) {
      const fn = switchFns['generateAll']
      return fn()
    } else if (runFront){
      const fn = switchFns['generateFront']
      return fn()
    } else if (runBack){
      const fn = switchFns['generateBack']
      return fn()
    }
  }