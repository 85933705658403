import React, { useState, useEffect } from "react";
import { AppContext } from "./libs/contextLib";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onError } from "./libs/errorLib";
import { Link } from 'react-router-dom';
import Routes from "./Routes";
import styled from "styled-components";

//Import Icons
import {ReactComponent as LogoLong} from './assets/blueprintLogo-Long.svg';
import {ReactComponent as HomeIcon} from './assets/home-icon.svg';
import {ReactComponent as FinanceIcon} from './assets/finance-icon.svg';
import {ReactComponent as MockPackIcon} from './assets/createMockupPackage-icon.svg';
import {ReactComponent as AddMockDataIcon} from './assets/addMockupData-icon.svg';
import {ReactComponent as LoginIcon} from './assets/login-icon.svg';
import {ReactComponent as LogoutIcon} from './assets/logout-icon.svg';
import {ReactComponent as PriceSheetIcon} from './assets/newpricesheet-icon.svg';
// Set layout components
const Layout = styled.div`
  display: grid;
  grid-template-areas:
    "sidebar content"
    "footer footer";
  
  grid-template-columns: 230px 1fr;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
`;

const Sidebar = styled.section`
  grid-area: sidebar;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width:100%;
  padding: 30px 0px 0px 0px;
`;


const Content = styled.section`
  grid-area: content;
  background-color: rgb(240,240,240);

`;

const Footer = styled.section`
  grid-area: footer;
  height: 100px;
  background-color: #000000;
`;

const NavBar = styled.nav`
  height: 380px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
  width: 100%;
  padding: 0px 0px 0px 20px;
`;

const MenuItem = styled.div`
  display: flex;
  flex: 1 1 auto;
  align-items: last baseline;
  flex-direction: row;
`;

const MenuItemText = styled.h4`
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 18px;
  font-weight: 600;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 6px;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  :focus, :hover, :visited, :link, :active {
      text-decoration: none;
  }
`;




function App() {
  const history = useHistory(); // Initiate the useHistory React Hook
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();         // Signs user out of AWS Auth
    userHasAuthenticated(false);  // Signs user out of React App
    history.push("/login")        // Push the "/login" path to history to redirect
  }

  return (
    !isAuthenticating && (
      <Layout>
        <Sidebar>
          <LogoLong width="165px"/>
          <NavBar>
            <StyledLink to='/'>
              <MenuItem>
                <HomeIcon width="30px" />
                <MenuItemText>Home</MenuItemText>
              </MenuItem>
            </StyledLink>
            {isAuthenticated && <StyledLink to='/create-mockup'>
                                  <MenuItem>
                                    <MockPackIcon width="30px" />
                                    <MenuItemText>Create Mockup</MenuItemText>
                                  </MenuItem>
                                </StyledLink>}
            {isAuthenticated && <StyledLink to='/newpricingsheet'>
                                  <MenuItem>
                                    <PriceSheetIcon width="30px" />
                                    <MenuItemText>New Pricing Sheet</MenuItemText>
                                  </MenuItem>
                                </StyledLink>}
            {isAuthenticated && <StyledLink to='/add-mockup-data'>
                                  <MenuItem>
                                    <AddMockDataIcon />
                                    <MenuItemText>Add Mockup Data</MenuItemText>
                                  </MenuItem>
                                </StyledLink>}
            {isAuthenticated && <StyledLink to='/finances'>
                                  <MenuItem>
                                    <FinanceIcon />
                                    <MenuItemText>Finances</MenuItemText>
                                  </MenuItem>
                                </StyledLink>}
            {isAuthenticated ? (<StyledLink to='/' onClick={handleLogout}>
                                  <MenuItem>
                                    <LogoutIcon />
                                    <MenuItemText>Logout</MenuItemText>
                                  </MenuItem>
                                </StyledLink> )
                                : 
                               (<StyledLink to='/login'>
                                  <MenuItem>
                                    <LoginIcon/>
                                    <MenuItemText>Login</MenuItemText>
                                  </MenuItem>
                                </StyledLink>)}
          </NavBar>
        </Sidebar>
        <Content>
          <AppContext.Provider value={{isAuthenticated, userHasAuthenticated}}>
            <Routes />
          </AppContext.Provider>
        </Content>
        <Footer></Footer>
      </Layout>
    )
  );
}

export default App;;
