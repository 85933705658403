import { useEffect} from 'react';

export const generateMockupReducer = (state, action) => {
  const fns = {
    'handleFileChange': () => {
      return {...state, [action.event.target.id]: action.event.target.files[0],
              [(action.event.target.id)+'Name']: action.event.target.files[0].name}
    },
    'setMockupState': () => {
      return {...state, 'projectName': action.projectName, 'brand': action.brand,
              'productCode': action.productCode, 'color': action.color}
    },
    'setLocationOption': () => {
      return {...state, [action.event.target.id]: action.event.target.value}
    },
    'handleCheckbox': () => {
      let bool = !state[action.event.target.id]
      return {...state, [action.event.target.id]: bool}
    }
  }
  const fn = fns[action.type]
  return fn()
}


export const useSyncRelativeStates = (generateMockupDispatch, projectName, brand,
                                      productCode, color) => {
  useEffect(() => {
    generateMockupDispatch({type: 'setMockupState', projectName: projectName,
                            brand: brand, productCode: productCode, color: color})
  },[generateMockupDispatch, projectName, brand, productCode, color])
}