import React from 'react';
import styled from 'styled-components';

import {FieldContainer} from './FieldContainer';
import {FieldLabel} from './FieldLabel';


// 
export const InputTextField = ({label, className, handleChange, id}) => {

  return (
    <FieldContainer className={className}>
      <FieldLabel label={label}/>
      <TextInput id={id} onChange={handleChange}/>
    </FieldContainer>
  )
};


const TextInput = styled.input`
  border-radius: 5px;
  width: 100%;
  height: 30px;
  padding: 8px 10px;
  border: 1px solid #007bff;
  font-size: 16px;
  transition: box-shadow 0.3s;
  &:focus {
      outline: none;
      box-shadow: 0px 0px 6px 2px rgba(79,156,239,0.3);
      border: 1px solid #4f9cef;
`;
