import React from "react";
import { Container, Header} from '../components/GlobalStyles';
import PricingTable from '../components/PricingSheet/PricingTable';
import { useTableFields } from "../libs/hooksLib";
import styled from 'styled-components';


export default function NewPricingSheet() {

  const colHeaders = ['','Brand', 'Product Code', 'Garment Cost', 'Inventory', 'Quantities',
                      'Front Ink Colors', 'Back Ink Colors', 'Stitch Count']

  const rowStructure = {
    brand: "",
    productCode: "",
    garmentCost: "",
    inventory: "",
    quantities: {
      xs:"",
      s:"",
      m:"",
      l:"",
      xl:"",
      xxl: "",
      xxxl: "",
      xxxxl: ""
    },
    frontInkColors: "",
    backInkColors: "",
    stitchCount: ""
  }
  // Declare table field hook
  const [lineItems, handleRowAdd, handleCellChange, handleRowDelete] = useTableFields(
    [rowStructure], rowStructure);
  
  // const hooks = {
  //   addRow: handleRowAdd,
  //   changeCell: handleCellChange,
  //   deleteRow: handleRowDelete
  // }

  return (
    <Container>
      <Header>Pricing Sheet</Header>
      <TableContainer>
        <PricingTable
          colHeaders={colHeaders}
          rowStructure={rowStructure} 
          lineItems={lineItems}
          changeCell={handleCellChange}
          addRow={handleRowAdd}
          deleteRow={handleRowDelete}
          />
      </TableContainer>
      <AddLineButton
        onClick={handleRowAdd}
      >Add Line Item</AddLineButton>
    </Container>
  )
}

// Styled Components

const TableContainer = styled.section`
  background: #003399;
  border: 1px solid #FFFFFF;
  border-radius: 4px;
  width: 100%;
  box-shadow: rgba(17, 51, 83, 0.02) 0px 4px 12px 0px;
`;

const AddLineButton = styled.button`
    background: #003399;
    border: 2px solid #FFFFFF;
    border-radius: 4px;
    box-shadow: rgba(17, 51, 83, 0.1) 0px 6px 12px 0px;
    color: white;
    margin: 50px 0px 0px 0px;
    padding: 5px 10px 5px 10px;
`;