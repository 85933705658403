import React from 'react';
import styled from 'styled-components';

import {MinorHeader} from './MinorHeader';


export const InventoryGrid = ({sizes, warehousesData, inventoryData}) => {
  if (inventoryData['L']) {
    const inventoryDataKeys = Object.keys(inventoryData);
    const warehouseDataKeys = Object.keys(inventoryData['L']);
    inventoryData.sizeKeys = inventoryDataKeys;
    inventoryData.warehouseKeys = warehouseDataKeys;
  }


  return(
    <InventoryGridContainer>
      <TableHeader label='Inventory'>Inventory</TableHeader> 
      {sizes.map((size, index) => {
        return (
          <SizeLabelCell key={'sizeLabelCell'+index} index={index+2}>
            <LargeCellLabel>
              {size}
            </LargeCellLabel>
          </SizeLabelCell>
      )})}
      {warehousesData.map((warehouse, index) => {
        return (
          <WarehouseCell key={'wareHouseCell'+index} location={warehouse.location}>
            <LargeCellLabel>{warehouse.location}</LargeCellLabel>
            <SmallCellLabel>{warehouse.time}</SmallCellLabel>
          </WarehouseCell>
        )
      })}
      {inventoryData['L'] && inventoryData.sizeKeys.map(sizeKey => {
        return (
          inventoryData.warehouseKeys.map((warehouseKey, index) => {
            return(
              <InventoryCell key={'inventoryCell'+index} warehouse={warehouseKey} size={sizeKey}>
                {inventoryData[sizeKey][warehouseKey]}
              </InventoryCell>
            )
          })
        )
      })}
    </InventoryGridContainer>
  )
}

const InventoryCell = styled.div`
  grid-area: ${props => props.warehouse.toLowerCase()+props.size.toLowerCase()};
`;

const SizeLabelCell = styled.div`
  grid-area: blank / ${props => props.index} / blank / ${props => props.index};
  align-self: center;
`;

const WarehouseCell = styled.div`
  grid-area: ${props => props.location.toLowerCase()} / 1 /
             ${props => props.location.toLowerCase()} / 1;
`;

const InventoryGridContainer = styled.div`
  display: grid;
  grid-template-areas:
    "header header header header header header header header header header"
    "blank xs   s   m   l   xl   xl2   xl3   xl4   xl5"
    "nj    njxs njs njm njl njxl nj2xl nj3xl nj4xl nj5xl"
    "ga    gaxs gas gam gal gaxl ga2xl ga3xl ga4xl ga5xl"
    "il    ilxs ils ilm ill ilxl il2xl il3xl il4xl il5xl"
    "ks    ksxs kss ksm ksl ksxl ks2xl ks3xl ks4xl ks5xl"
    "tx    txxs txs txm txl txxl tx2xl tx3xl tx4xl tx5xl"
    "nv    nvxs nvs nvm nvl nvxl nv2xl nv3xl nv4xl nv5xl"
    "ds    dsxs dss dsm dsl dsxl ds2xl ds3xl ds4xl ds5xl";
  
  grid-template-columns: repeat(10, 45px);
  grid-template-rows: repeat(8, 45px);
  justify-items: center;
  align-items: center;

`;

const TableHeader = styled(MinorHeader)`
  && {
  grid-area: header / blank / header / xl5;
  font-size: 20px;
  text-align: center;
  align-self: end;
  padding: 0px 0px 0px 0px;
  }
`;

const LargeCellLabel = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  letter-spacing: -0.31px;
  text-align: center;
  padding: 0px 5px 0px 5px;
  margin: 0px 0px 0px 0px;
`;

const SmallCellLabel = styled.p`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-size: 10px;
  color: #000000;
  letter-spacing: -0.31px;
  text-align: center;
  padding: 0px 5px 0px 5px;
  margin: 0px 0px 0px 0px;
`;
