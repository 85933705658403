import React from 'react';
import styled from 'styled-components';

export const ContentContainer = (props) => {

  return(
    <ContentContainerDiv className={props.className}>
      {props.children}
    </ContentContainerDiv>
  )
}

const ContentContainerDiv = styled.div`
  padding: 20px;
`;