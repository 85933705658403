import styled from 'styled-components';

export const Container = styled.div`
	padding: 20px;
`;
export const Header = styled.h1`
	font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 36px;
  font-weight: 650;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 40px 0px 40px 6px;
`;