import React, {useState, useEffect} from 'react';
import { API } from "aws-amplify";
import { onError } from "../libs/errorLib";
import {useFormFields} from '../libs/hooksLib';
import { InputRow } from '../components/InputRow';
import {MinorHeader} from '../components/MinorHeader';
import {InventoryGrid} from '../components/InventoryGrid';
export default function TestPage() {
const initInventoryData = {'S': {'IL': 0, 'NV': 0, 'NJ': 0, 'KS': 0, 'DS': 0, 'TX': 0, 'GA': 0},
                'M': {'NJ': 0, 'IL': 0, 'NV': 0, 'GA': 0, 'TX': 0, 'DS': 0, 'KS': 0},
                'L': {'NJ': 0, 'KS': 0, 'IL': 0, 'NV': 0, 'GA': 0, 'TX': 0, 'DS': 0},
                'XL': {'NJ': 0, 'KS': 0, 'DS': 0, 'TX': 0, 'GA': 0, 'NV': 0, 'IL': 0},
                '2XL': {'NJ': 0, 'KS': 0, 'DS': 0, 'TX': 0, 'GA': 0, 'NV': 0, 'IL': 0},
                '3XL': {'NJ': 0, 'KS': 0, 'DS': 0, 'TX': 0, 'GA': 0, 'NV': 0, 'IL': 0},
                '4XL': {'NJ': 0, 'KS': 0, 'DS': 0, 'TX': 0, 'GA': 0, 'NV': 0, 'IL': 0},
                '5XL': {'IL': 0, 'NV': 0, 'GA': 0, 'TX': 0, 'DS': 0, 'KS': 0, 'NJ': 0}}
  // Note: useState automatically memoizes the functions declared
  const [brands, setBrands] = useState(['']);
  const [productCodes, setProductCodes] = useState(['']);
  const [colors, setColors] = useState(['']);
  const [inventory, setInventory] = useState(initInventoryData)

  const [fields, setField, handleFieldChange] = useFormFields({
    brand: "",
    productCode: "",
    color: ""})
  

  useEffect(() => {
    function getBrands() {
      return API.get("blueprint-mockup-api", "/ssGetBrands")
    }
    const onLoad = async () => {
      try {
        const newData = await getBrands();
        console.log(newData);
        setBrands(arr => [...arr, ...newData]);
      } catch(e) {
        onError(e)
      }
      // setIsLoading(false);
    }
    onLoad();
  }, [setField])

  useEffect(() => {
    function getProductCodes() {
      const params = {
        queryStringParameters: {
          brand: fields.brand
        }
      }
      console.log('codes')
      console.log(params)
      return API.get("blueprint-mockup-api", "/ssGetProductCodes", params)
    }
    const onBrandChange = async () => {
      const optionValue = document.getElementById("brand").value; 
      console.log(optionValue)
      if (!optionValue) {
        return
      }
      try {
        const newData = await getProductCodes();
        console.log(newData);
        setProductCodes(['']);
        setProductCodes(arr => [...arr, ...newData]);
      } catch(e) {
        onError(e)
      }
      // setIsLoading(false);
    }
    onBrandChange();
  }, [fields.brand])


  useEffect(() => {
    const getColors = async () => {
      const params = {
        queryStringParameters: {
          brand: fields.brand,
          productCode: fields.productCode
        }
      }
      console.log(params)
      return API.get("blueprint-mockup-api", "/ssGetColors", params)
    }
    const onProductCodeChange = async () => {
      const optionValue = document.getElementById("productCode").value;
      if(!optionValue){
        return
      }
      try {
        const newData = await getColors();
        setColors([''])
        setColors(arr => [...arr, ...newData]);
      } catch (e) {
        onError(e)
      }
    }
    onProductCodeChange()
  }, [fields.productCode, fields.brand])

  useEffect(() => {
    const getInventory = async () => {
      const params = {
        queryStringParameters: {
          brand: fields.brand,
          productCode: fields.productCode,
          color: fields.color
        }
      }
      console.log(params)
      return API.get("blueprint-mockup-api", "/ssGetInventory", params)
    }
    const onColorChange = async () => {
      const optionValueBrand = document.getElementById("brand").value;
      const optionValueProductCode = document.getElementById("productCode").value;
      const optionValueColor = document.getElementById("color").value;
      if(!(optionValueBrand&&optionValueProductCode&&optionValueColor)){
        return
      }
      try {
        const newData = await getInventory();
        console.log(newData)
        setInventory(newData);
      } catch (e) {
        onError(e)
      }
    }
    onColorChange()
  }, [fields.productCode, fields.brand, fields.color])

  

  // Get Data from Blueprint Finances Table



  const sizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
  const warehouseData = [{location: 'NJ', time: '1 day'}, {location: 'GA', time: '2 day'},
                  {location: 'IL', time: '3 day'}, {location: 'KS', time: '3 day'},
                  {location: 'TX', time: '4 day'}, {location: 'NV', time: '4 day'},
                  {location: 'DS', time: '1 week'}]


  const printFields = async () => console.log(await API.get("blueprint-mockup-api", "/ssGetBrands"));
  return(
    <>
      <MinorHeader label={'Testing'}></MinorHeader> 
      <InputRow inputs={[{label: 'Brand', type: 'dropdown', id:'brand', value: fields.brand, handleChange: handleFieldChange, options: brands},
                         {label: 'Product Code', type: 'dropdown', id:'productCode', value: fields.productCode, handleChange: handleFieldChange, options: productCodes},
                         {label: 'Color', type:'dropdown', id: 'color', value: fields.colors, handleChange: handleFieldChange, options: colors}]}/>
      <InventoryGrid
        sizes={sizes}
        warehousesData={warehouseData}
        inventoryData={inventory}>
      </InventoryGrid>
      <button onClick={printFields}></button>
    </>
  )
}