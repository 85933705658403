import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router} from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import config from './config';

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  Storage: {
    region: config.s3.REGION,
    bucket: config.s3.BUCKET,
    identityPoolId: config.cognito.IDENTITY_POOL_ID
  },
  API: {
    endpoints: [
      {
        name: "blueprint-mockup-api",
        endpoint: config.apiGatewayPython.URL,
        region: config.apiGatewayPython.REGION
      },
      {
        name: "blueprint-finances-api",
        endpoint: config.apiGatewayJS.URL,
        region: config.apiGatewayJS.REGION
      }
    ]
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);;

