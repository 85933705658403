import React from 'react';
import styled from 'styled-components';
import {FieldContainer} from './FieldContainer';
import {FieldLabel} from './FieldLabel';



export const UploadFile = ({label, id, file, handleChange, className}) => {
  return(
    <> 
      <FieldContainer className={className}>
        <FieldLabel label={label} fontWeight='700' fontSize='14px'></FieldLabel>
        <FileUploadWrapper>
          <FileUploadInput
            name="filename" 
            type="file" 
            id={id}
            onChange={handleChange}/>
            Choose File
        </FileUploadWrapper>
        <FileStatusP>{file && file.name}</FileStatusP>
      </FieldContainer>
    </>
  )
}

const FileStatusP = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  letter-spacing: -0.31px;
  text-align: left;
  margin: 0px 0px 0px 0px;
  padding: 2px 0px 0px 5px;
`;

const FileUploadWrapper = styled.label`
  width: 100%;
  height: 30px;
  border-radius: 7px;
  border: 2px solid #979797;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  letter-spacing: -0.31px;
  text-align: left;
  margin: 0px 0px 0px 0px;
  padding: 2px 0px 0px 5px;
  cursor: pointer;
`;

const FileUploadInput = styled.input`
  display: none;
`; 

