import React from 'react';
import styled from 'styled-components';

import {MinorHeader} from './MinorHeader';
import {InputTextField} from './InputTextField';

export const DecorationCard = ({initDecorationState, decorationDispatch}) => {

  return (
    <>
      <QuantitiesCardDiv>
        <CardHeader fontSize='20px' label='Decoration'/>
        <InputTextField
          id={Object.keys(initDecorationState)[0]} 
          label='Front Ink Colors'
          handleChange={(event) => decorationDispatch({type: 'handleFieldChange', event})}/>
        <InputTextField
          id={Object.keys(initDecorationState)[1]} 
          label='Back Ink Colors'
          handleChange={(event) => decorationDispatch({type: 'handleFieldChange', event})}/>
        <InputTextFieldSpecGrid 
          id={Object.keys(initDecorationState)[2]} 
          label='Stitch Count'
          handleChange={(event) => decorationDispatch({type: 'handleFieldChange', event})}/>
      </QuantitiesCardDiv>
    </>
  )
}

const CardHeader = styled(MinorHeader)`
  grid-area: header;
  align-self: end;
`;

const InputTextFieldSpecGrid = styled(InputTextField)`
  grid-area: stitchCount;
`;

const QuantitiesCardDiv = styled.div`
  grid-area: decoration;
  padding: 10px 0px 10px 0px;
  margin: 20px 0px 0px 0px;
  width: 100%;
  display: grid;
  grid-template-areas:
    "header      header"
    "frontInk    backInk"
    "stitchCount stitchCount";
  
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto;
  gap: 10px 10px;
  justify-items: center;
  align-items: center;
  border-left: 2px solid #979797;
`;