import React, {useCallback} from 'react';
import styled from 'styled-components';
import {MinorHeader} from "./MinorHeader";
import {UploadFile} from "./UploadFile";
import {InputDropdown} from './InputDropdown';
import {PillButton} from './PillButton';
import {Checkbox} from './Checkbox';
import {generateMockup} from '../libs/mockupLib';


export const GenerateMockupCard = ({generateMockupState, generateMockupDispatch}) => {

  const generateMockupCallback = useCallback( () => {
      generateMockup(generateMockupState)
    },
    [generateMockupState])

  return(
    <>
      <GenerateMockupCardDiv>
        <CardHeader fontSize='20px' label='Generate Mockup'/>
        <FileContainer>
          <UploadFile
            label='Upload Front Design' 
            id='frontLogoFile'
            file={generateMockupState.frontLogoFile}
            handleChange={(event) => generateMockupDispatch({type: 'handleFileChange', event: event})}/>
          <Checkbox 
            label='Remove Background' 
            id='removeFrontBG' 
            value={false}
            handleChange={(event) => generateMockupDispatch({type: 'handleCheckbox', event: event})}/>
        </FileContainer>
        <FileContainer>
          <UploadFile
            label='Upload Back Design' 
            id='backLogoFile'
            file={generateMockupState.backLogoFile}
            handleChange={(event) => generateMockupDispatch({type: 'handleFileChange', event: event})}/>
          <Checkbox 
            label='Remove Background' 
            id='removeBackBG' 
            value={false}
            handleChange={(event) => generateMockupDispatch({type: 'handleCheckbox', event: event})}/>  
        </FileContainer>
        <InputDropdown
          label='Front Location'
          options={['', 'Left Chest', 'Full Chest']}
          id='frontLocation'
          handleChange={(event) => generateMockupDispatch({type: 'setLocationOption', event: event})}
        />
        <InputDropdown
          label='Back Location'
          options={['', 'Full Back']}
          id='backLocation'
          handleChange={(event) => generateMockupDispatch({type: 'setLocationOption', event: event})}
        />
        <GenerateButton label='Generate' onClick={generateMockupCallback}/>
      </GenerateMockupCardDiv>
    </>
  )
}

const CardHeader = styled(MinorHeader)`
  grid-area: header;
  align-self: end;
`;

const GenerateButton = styled(PillButton)`
  grid-area: generateButton;
  align-self: center;
`;

const GenerateMockupCardDiv = styled.div`
  grid-area: mockup;
  padding: 10px 0px 10px 0px;
  margin: 0px 20px 20px 20px;
  width: 100%;
  display: grid;
  grid-template-areas:
    "header         header"
    "uploadFront    uploadBack"
    "locationFront  locationBack"
    "generateButton generateButton";
  
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  gap: 10px 10px;
  justify-items: center;
  border-left: 2px solid #979797;
  border-top: 2px solid #979797;
`;

const FileContainer = styled.div`
  height: 100%;
  width: 100%
`;