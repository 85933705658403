import { useState } from "react";

export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);   // Create a state hook 
    // Return the same value that a state hook alone would return. ie. Return the
    // setValue function as the second argument and declare a custom function that
    // takes the "event" as an argument.
    console.log('initing')
  return [
    fields,     // Return value of hook
    event => {       // Return state update function name
      setValues({
        ...fields,      // Make properties in fields accessible
        [event.target.id]: event.target.value   // Replace the prop corresponding to the target id
                                                // with the value of the target
      });
    }
  ];
}

export function useTableFields(initialRows, newRow) {
  const [cells, setCells] = useState(initialRows);
  return [
    cells,
    // Handle Add Job
    function(e) {
      // let i = e.target.getAttribute("index");
      // for (const [key, value] of Object.entries(newRow)) {
      //   const newKey = key+i
      // }
      setCells(arr => [...arr, newRow]);
      console.log(cells)

    },
    // Handle Cell Change
    function(e) {
      // console.log(data)
      let i = e.target.getAttribute("data-index");
      let dataId = e.target.getAttribute("data-id");
      // console.log(i)
      // console.log(e.target.value)
      let key = dataId
      let newArr = [...cells]
      // console.log(newArr)
      let dataId2 = e.target.getAttribute("data-id2");
      // console.log(typeof(dataId2))
      // dataId2 ? newArr[key][dataId2] = e.target.value : newArr[i][key] = e.target.value;
      console.log(newArr[i][key])
      dataId2 ? console.log("Test") : newArr[i][key] = e.target.value;
      // console.log(cells)
      // newArr[i][key] = e.target.value;
      setCells(newArr);
      // console.log(newArr)
    },
    // Handle Delete
    function(e) {
      let i = Number(e.target.getAttribute("data-index"));
      console.log(i)
      setCells(cells.filter((item, index) => index !==i))
      console.log(cells)
    },
    // Set new Jobs
    setCells
  ];
};