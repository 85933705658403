import React, {useReducer} from 'react';
import styled from 'styled-components';
import {InventoryCard} from './InventoryCard';
import {GenerateMockupCard} from './GenerateMockupCard';
import {QuantitiesCard} from './QuantitiesCard';
import {DecorationCard} from './DecorationCard';
import {CostOutputsCard} from './CostOutputsCard';

import {inventoryFilterReducer} from "../hooks/inventoryHooks";
import {generateMockupReducer, useSyncRelativeStates} from '../hooks/generateMockupHooks'
import {quantityReducer} from '../hooks/quantityHooks';
import {decorationReducer} from '../hooks/decorationHooks';
// import {useGetContractorPricingOnMount} from '../hooks/costOutputHooks'
import { PillButton } from './PillButton';

const initFilterKeys = {
  brand: "",
  productCode: "",
  color: ""
}

const initGenerateMockup = {
  projectName: '',
  brand: '',
  productCode: '',
  color: '',
  frontLocation: '',
  frontLogoFile: null,
  frontLogoFileName: '',
  removeFrontBG: false,
  backLocation: '',
  backLogoFile: null,
  backLogoFileName: '',
  removeBackBG: false
}

const initQuantityState = {
  min: '',
  gtr1: '',
  gtr2: '',
  gtr3: ''
}

const initDecorationState = {
  frontInkColors: '',
  backInkColors: '',
  stitchCount: ''
}

// const initCostOutputState = {
//   contractors: [{
//     inkCost    : ['', '', '', ''],
//     stitchCost : ['', '', '', ''],
//     setupCosts : ['', '', '', ''],
//     totalCosts : ['', '', '', '']
//   }],
//   garmentCosts: [{
//     each: '',
//     total: ''
//   }],
//   clientCosts: [{
//     perGarment: '',
//     total: ''
//   }],
//   profits: [{
//     margin: '',
//     profit: ''
//   }]
// }

// const contractor1 = 'phoenixScreenprintPricing'
export const PricingBox = ({projectName}) => {
  const [generateMockupState, generateMockupDispatch] = useReducer(generateMockupReducer, initGenerateMockup)
  const [filterState, filterDispatch] = useReducer(inventoryFilterReducer, initFilterKeys)
  const [quantityState, quantityDispatch] = useReducer(quantityReducer, initQuantityState)
  const [decorationState, decorationDispatch] = useReducer(decorationReducer, initDecorationState)
  // const [costOutputState, costOutputDispatch] = useReducer(costOutputReducer, initCostOutputState)
  useSyncRelativeStates(generateMockupDispatch, projectName, filterState.brand,
                        filterState.productCode, filterState.color)
  // const phoenixTableName = useMemo(() => thingName, []) 
  // const thing = useGetContractorPricingOnMount(contractor1)
  const testFun = (event) => {
    event.preventDefault();
    console.log(quantityState)
    console.log(decorationState)
    console.log(generateMockupState)
  }
  return(
    <>
      <PricingBoxGrid>
        <InventoryCard filterState={filterState} filterDispatch={filterDispatch}/>
        <GenerateMockupCard
          projectName={projectName}
          filterState={filterState}
          generateMockupState={generateMockupState}
          generateMockupDispatch={generateMockupDispatch}/>
        <QuantitiesCard
          initQuantityState={initQuantityState}
          quantityDispatch={quantityDispatch}/>
        <DecorationCard
          initDecorationState={initDecorationState}
          decorationDispatch={decorationDispatch}/>
        <CostOutputsCard/>
      </PricingBoxGrid>
      <PillButton
        label='Testing'
        onClick={testFun}
      ></PillButton>
    </>
  )
}

const PricingBoxGrid = styled.div`
  display: grid;
  grid-template-areas:
    "supply      quantities  decoration"
    "supply      quantities  mockup"
    "costOutputs costOutputs costOutputs";
  
  grid-template-columns: 2fr 2fr 2fr;
  grid-template-rows: 2fr 3fr 3fr;
  justify-items: center;
  background: #FFFFFF;
  border: 1px solid #979797;
  border-radius: 6px;
`;
