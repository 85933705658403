

export const quantityReducer = (state, action) => {
  const fns = {
    'handleFieldChange': () => {
      return {...state, [action.event.target.id]: action.event.target.value}
    }
  }
  const fn = fns[action.type]
  return fn()
}
