import React from 'react';
import styled from 'styled-components';

import { InputField } from './InputField';


export const InputRow = ({inputs}) => {
  return(
    <RowContainer>
      {inputs.map(input => (
        <InputField
          label={input.label}
          type={input.type}
          id={input.id}
          key={input.id}
          value={input.value}
          handleChange={input.handleChange}
          options={input.options ? input.options : ['']}
         >
         </InputField>
      ))}
    </RowContainer>
  )
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

