import React from 'react';
import styled from 'styled-components';

import {FieldContainer} from './FieldContainer';
import {FieldLabel} from './FieldLabel';


export const Checkbox = ({label, id, value, className, handleChange}) => {
  return (
    <FieldContainer className={className}>
      <CheckboxLabel label={label} fontSize='14px'/>
      <CheckboxInput type='checkbox' id={id} name={id} onChange={handleChange}/>
      <ValueStore type='hidden' id={id} value={value}/>
    </FieldContainer>
  )
}

const CheckboxLabel = styled(FieldLabel)`
  font-weight: 800;
`;
const CheckboxInput = styled.input`
  width: 20px;
  height: 20px;
  margin: 5px 0px 0px 10px;
`;

const ValueStore = styled.input``;