import React from 'react';
import styled from 'styled-components';




export default function PricingTable({colHeaders, rowStructure, changeCell, addRow, deleteRow, lineItems}) {


  const dataKeys = Object.keys(rowStructure);
  const sizeKeys = Object.keys(rowStructure.quantities)
  const renderTableRows = lineItems.map((lineItem, index) => {
    return (
      <TableRow id={"row"+index} key={"row"+index}>
        <ColumnCell width='50px'>
          <InputCell>
            <InnerCellLabel>Line Item</InnerCellLabel>
            <DeleteButton
              onClick={deleteRow}
              data-index={index}>
              Delete
            </DeleteButton>
          </InputCell>
        </ColumnCell>
        <ColumnCell width='200px'>
          <InputCell>
            <InnerCellLabel>{dataKeys[0][0].toUpperCase()+dataKeys[0].substring(1)}</InnerCellLabel>
            <InputField
              id={dataKeys[0]+index}
              key={dataKeys[0]+index}
              data-id={dataKeys[0]}
              data-index={index}
              type='text'
              // value={lineItems[index][dataKeys[0]]}
              value={lineItem[dataKeys[0]]||''}
              onChange = {changeCell}/>
          </InputCell>
        </ColumnCell>
        <ColumnCell width='200px'>
          <InputCell>
            <InnerCellLabel>Product Code</InnerCellLabel>
            <InputField
              id={dataKeys[1]+index}
              key={dataKeys[1]+index}
              data-id={dataKeys[1]}
              data-index={index}
              type='text'
              value={lineItem[dataKeys[1]]||''}
              onChange = {changeCell}
            />
          </InputCell>
        </ColumnCell>
        <ColumnCell width='50px'>
          <QtyBox>
              {sizeKeys.map((size, index) => {
                return(
                  <QtyCell key={"qtyCell"+index}>
                    <InnerCellLabel>{size}</InnerCellLabel>
                    <InputField
                      id={size+index}
                      key={size+index}
                      data-id={dataKeys[4]}
                      data-id2 = {size}
                      data-index={index}
                      type='text'
                      value={lineItem[dataKeys[4]][size]||''}
                      onChange={changeCell}
                    />
                  </QtyCell>
                )
              })}
          </QtyBox>
        </ColumnCell>
        <ColumnCell>
          <InputCell>
            <InnerCellLabel>Front Ink Colors</InnerCellLabel>
            <InputField
              id={dataKeys[5]+index}
              key={dataKeys[5]+index}
              data-id={dataKeys[5]}
              data-index={index}
              type='text'
              value={lineItem[dataKeys[5]]||''}
              onChange={changeCell}
            />
          </InputCell>
        </ColumnCell>

        <ColumnCell>
          <InputCell>
              <InnerCellLabel>Back Ink Colors</InnerCellLabel>
              <InputField
                id={dataKeys[6]+index}
                key={dataKeys[6]+index}
                data-id={dataKeys[6]}
                data-index={index}
                type='text'
                value={lineItem[dataKeys[6]]||''}
                onChange={changeCell}
              />
          </InputCell>
        </ColumnCell>
        <ColumnCell>
            <InputCell>
              <InnerCellLabel>Stitch Count</InnerCellLabel>
              <InputField
                id={dataKeys[7]+index}
                key={dataKeys[7]+index}
                data-id={dataKeys[7]}
                data-index={index}
                type="text"
                value={lineItem[dataKeys[7]]||''}
                onChange={changeCell}
              />
            </InputCell>
        </ColumnCell>
      </TableRow>
    )
  })

  return(
    <>
    <Table>
      <thead>
        <HeaderRow>
          <SingleHeader
            colSpan='10'
          >Blueprint Pricing Sheet</SingleHeader>
        </HeaderRow>
      </thead>
      <TableBody>
        {renderTableRows}
      </TableBody>
    </Table>
    <button
      onClick={addRow}>
      Print
    </button>


    </>
  )
}


// Styled Components
const Table = styled.table`
  width: 100%;
`;

const SingleHeader = styled.th`
  color: #FFFFFF;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;

`;

const TableBody = styled.tbody`
  background: #FFFFFF;
  padding: 0px;
`;

const HeaderRow = styled.tr`
  height: 40px;
`;

const TableRow = styled.tr`
  height: 40px;
  margin: 10px 0px 10px 0px;
`;

const ColumnCell = styled.td`
  border-top: 1px solid rgb(236, 239, 241);
  cursor: default;
  padding: 15px 5px 15px 5px;
  width: ${props => props.width ? props.width : '100px'};
`;

const QtyBox = styled.div`
  display: grid;
  grid-template-areas:
    "xs small medium large"
    "xl xxl xxxl xxxxl";
  grid-template-columns: 40px 40px 40px 40px;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
`;

const QtyCell = styled.div`
  display: flex;
  flex-direction: column;
`;

const InnerCellLabel = styled.p`
  font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 15px;
  font-weight: 700;
  text-align: left;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 0px 0px 0px 0px;
`;

const InputCell = styled.div`
  // display: flex;
  // flex-direction:column;
  align-items: left;
  padding: 0px 0px 18px 0px;
  margin: 0px 0px 0px 0px;
  
`;

const InputField = styled.input`
  width: 100%;
  :hover {
    border: 1px solid blue;
    border-radius: 4px
  }
`;


const DeleteButton = styled.button`
  background: #D40500;
  border: 2px solid #000000;
  border-radius: 4px;
  box-shadow: rgba(17, 51, 83, 0.1) 0px 6px 12px 0px;
  color: white;
  margin: 10px 0px 0px 0px;
  padding: 5px 5px 5px 5px;
  width: 100px;

`;

