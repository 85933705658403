import React from 'react';
import styled from 'styled-components';

import {MinorHeader} from './MinorHeader';
export const ContractorCostCols = ({label, startRow, startCol}) => {
  const pos = (row, col) => 'r'+String(row)+'c'+String(col) 
  return (
    <>
      <ColsTitle
        position={pos(startRow, startCol)+'/'+pos(startRow, startCol)+'/'+
                  pos(startRow, startCol)+'/'+pos(startRow+3, startCol+3)}
        label={label}/>
      <CostLabel
        position={pos(startRow+1, startCol)}
        label='Ink Cost'/>
      <CostLabel
        position={pos(startRow+1, startCol+1)}
        label='Stitch Cost'/>
      <CostLabel 
        position={pos(startRow+1, startCol+2)}
        label='Setup Costs'/>
      <CostLabel
        position={pos(startRow+1, startCol+3)}
        label='Total Costs'/>
    </>
  )
}

const ColsTitle = styled(MinorHeader)`
  grid-area: ${props => props.position};
  font-size: 18px;
  white-space: nowrap;
  justify-self: center;
`;

const CostLabel = styled(MinorHeader)`
  grid-area: ${props => props.position};
  font-size: 14px;
  white-space: nowrap;
  justify-self: center;
`;
