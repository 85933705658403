import React, {useEffect, useState} from "react";



export default function FinanceData({data}) {
  const jobData = data;
  const [currentBalance, setCurrentBalance] = useState(0);

  const [amountOwed, setAmountOwed] = useState(null);
  const [netBalance, setNetBalance] = useState(0);



  useEffect(() => {
    const calcAmountOwed = () => {
      let owedArray=[];
      for (let i=0; i < jobData.length; i++){
        let owed = jobData[i].unpaidAmnt
        owedArray.push(owed)
      }
      let sum = owedArray.reduce((a, b) => Number(a)+Number(b), 0); 
      setAmountOwed(sum);
    }
    const calcNetBalance = () => {
      let netBal = Number(currentBalance)+Number(amountOwed)
      setNetBalance(netBal);
    }
    calcAmountOwed();
    calcNetBalance();
  
  },[jobData, amountOwed, currentBalance])

  return(
    <div className="FinanceData">
      <table style={{width:"100%"}}>
        <thead>
          <tr>
            <th>
              Current Balance
            </th>
            <th>
              Amount Owed
            </th>
            <th>
              Net Balance
            </th>
          </tr>
        </thead>
        <tbody>
          <tr style={{width:"100%"}}>
            <td>
              <input
                id="Current Balance"
                style={{width:"33%"}}
                type="number"
                value={currentBalance}
                onChange={(e) => setCurrentBalance(e.target.value)}
              />
            </td>
            <td>
              {amountOwed}
            </td>
            <td>
              {netBalance}
            </td>
          </tr>
        </tbody>
      </table>
      {/* <Button
        onClick={jobData => calcAmountOwed(jobData)}
        block>
        Calculate
      </Button> */}

    </div>
  )
}