import { useEffect, useState, useRef} from 'react';
import { API } from 'aws-amplify';
import { onError } from '../libs/errorLib';

// NOTE: Custom hooks need to start with 'use' or linter will yell at you

export const inventoryFilterReducer = (state, action) => {
  const fns = {
    'handleOptionChange': () => {
      return {...state, [action.event.target.id]: action.event.target.value}
    },
    'setOption': () => {
      return {...state, [action.option]: action.value}
    }
  }
  const fn = fns[action.type]
  return fn()
}

export const useGetBrandsOnMount = (filterDispatch) => {
  const [brands, setBrands] = useState([''])
  useEffect(() => {
    const getBrands = async () => {
      try{
        const pulledBrands = await API.get('blueprint-mockup-api', '/ssGetBrands');
        setBrands(pulledBrands)
        filterDispatch({type: 'setOption', option: 'brand', value: pulledBrands[0]})
      } catch(e) {
        onError(e)
      }
    }
    getBrands()
  }, [filterDispatch])
  return brands
}

export const useGetCodesOnBrandChange = (brand, filterDispatch ) => {
  const [productCodes, setProductCodes] = useState(['']);
  useEffect(() => {
    if (brand) {
      const params = {
        queryStringParameters: {
          brand: brand
        }
      }
      const getProductCodes = async () => {
        try{
          const pulledProductCodes = await API.get('blueprint-mockup-api', '/ssGetProductCodes', params)
          setProductCodes(pulledProductCodes)
          filterDispatch({type: 'setOption', option: 'productCode', value: pulledProductCodes[0]})
        } catch(e) {
          onError(e)
        }
      }
      getProductCodes()
    }
  }, [brand, filterDispatch])
  return productCodes;
}

export const useGetColorsOnCodeChange = (brand, productCode, filterDispatch) => {
  const [colors, setColors] = useState([''])
  const prevProductCodeRef = useRef()
  useEffect(() => {
    if (brand && productCode && productCode !== prevProductCodeRef.current){
      prevProductCodeRef.current = productCode;
      const params = {
        queryStringParameters: {
          brand: brand,
          productCode: productCode
        }
      }
      const getColors = async () => {
        try{
          const pulledColors = await API.get('blueprint-mockup-api', '/ssGetColors', params)
          setColors([])
          setColors(pulledColors)
          filterDispatch({type: 'setOption', option: 'color', value: pulledColors[0]})
        } catch(e) {
          onError(e)
        }
      }
      getColors()
    }
  }, [brand, productCode, filterDispatch])
  return colors;
}


export const useGetInventoryOnColorChange = (brand, productCode, color) => {
  const [inventory, setInventory] = useState({});
  const prevProductCodeRef        = useRef()
  const prevColorRef              = useRef()
  useEffect(() => {
    if(brand && productCode && color &&
      (productCode !== prevProductCodeRef.current || color!==prevColorRef.current)){
      // Set refs to ensure changes
      prevProductCodeRef.current = productCode
      prevColorRef.current       = color
      const params = {
        queryStringParameters: {
          brand: brand,
          productCode: productCode,
          color: color
        }
      }
      const getInventory = async () => {
        try {
          const pulledInventory = await API.get('blueprint-mockup-api', '/ssGetInventory', params)
          setInventory(pulledInventory)
        } catch (e) {
          onError(e)
        }
      }
      getInventory()
    }
  }, [brand, productCode, color])
  return inventory;
}
