import React from "react";
import { Route, Switch } from "react-router-dom";
import {CreateMockup2} from "./pages/CreateMockup2";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AddMockupData from "./pages/AddMockupData";
import Finances from "./pages/Finances";
import NewPricingSheet from "./pages/NewPricingSheet";
import TestPage from "./pages/TestPage";
import {TestPit} from "./pages/TestPit";
import NotFound from "./pages/NotFound";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/">
        <Home />
      </Route>
      <Route exact path='/login'>
        <Login />
      </Route>
      <Route exact path='/create-mockup'>
        <CreateMockup2 />
      </Route>
      <Route exact path='/add-mockup-data'>
        <AddMockupData /> 
      </Route>
      <Route exact path='/finances'>
        <Finances />
      </Route>
      < Route exact path='/newpricingsheet'>
        <NewPricingSheet />
      </Route>
      < Route exact path='/testpage'>
        <TestPage />
      </Route>
      <Route exact path='/testpit'>
        <TestPit/>
      </Route>
      {/* Finally, catch all unmatched routes */}
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}