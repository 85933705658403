import React, {useEffect, useState} from 'react';
import {s3getImage} from "../libs/awsLib";
import { PDFViewer, Document, Page, Image, View, StyleSheet } from '@react-pdf/renderer';
import {InputTextField} from '../components/InputTextField';
import styled from 'styled-components';
import {ContentContainer} from '../components/ContentContainer';
import {MinorHeader} from '../components/MinorHeader';
import {UploadFile} from '../components/UploadFile';
import {InputRow2} from '../components/InputRow2';
//// NOTE: Styled components is outdated for React-PDF styling

const Doc = ({logo}) => {
  const styles = StyleSheet.create({
    mockupPage: {
      backgroundColor: '#FFFFFF',
    },
    header: {
      // display: 'flex',
      // flexDirection: 'row',
      margin: 10,
      padding: 10,
      // flexGrow: 1,
    },
    box: {
      position: 'relative',
      height: 200,
      width: 200,
      top: 100
    },
    logoImage: {
      // height: 50,
      position: 'absolute',
      top: 0,
      left: 0,
      height: 50
    },
    logoImage2: {
      // height: 50,
      position: 'absolute',
      top:30,
      left: 20,
      height: 50
    }, 
    headerHeader: {
      fontSize: 30
    }
  })

  return(
      <Document>
        <Page size="A4" style={styles.mockupPage}>
          <View style={styles.header}>

            <View style={styles.box}>
              {logo && <Image src={logo} style={styles.logoImage}/>}
              {logo && <Image src={logo} style={styles.logoImage2}/>}
            </View>
          </View>
        </Page>
      </Document>
  )
}

export const TestPit = () => {
  const [blueprintLogo, setBlueprintLogo] = useState(null)
  const [projectName, setProjectName]     = useState(null)
  const [frontLogoFile, setFrontLogoFile] = useState(null)
  const [backLogoFile, setBackLogoFile]   = useState(null)
  useEffect(() => {
    const getBlueprintLogo = async () => {
      const tempImg = await s3getImage('pdfAssets/Web Small.png')
      setBlueprintLogo(tempImg)
    }
    getBlueprintLogo()
  }, [])

  console.log(projectName)
  return(
    <>
      <FullContentContainer>
        <MinorHeader label='Blueprint Salesheet Generator'/>
        <InputRow2>
          <ProjectNameInput 
            id='projectName'
            label='Project Name'
            handleChange={(e)=> setProjectName(e.target.value)}/>
          <LogoUpload
            label='Upload Front Design'
            id='frontLogoFile'
            file={frontLogoFile}
            handleChange={(e) => {setFrontLogoFile(e.target.files[0])}}/>
          <LogoUpload
            label='Upload Back Design'
            id='backLogoFile'
            file={backLogoFile}
            handleChange={(e) => {setBackLogoFile(e.target.files[0])}}/>
        </InputRow2>
        {/* <PDFDownloadLink document={<Doc />} fileName="somename.pdf">
          {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Download now!')}
        </PDFDownloadLink> */}
        <PDFViewer width='100%' height='85%'>
          <Doc logo={blueprintLogo}/>
        </PDFViewer>
      </FullContentContainer>
    </>
  )
}

const ProjectNameInput = styled(InputTextField)`
  padding: 0px 0px 10px 0px;
  min-width: 200px;
`;

const FullContentContainer = styled(ContentContainer)`
  height: 100vh;
  // width: 100%;
`;

const LogoUpload = styled(UploadFile)`
  margin: 6px 0px 0px 0px;
`;


