import React from 'react';
import styled from 'styled-components';

import {MinorHeader} from './MinorHeader';
import {InputTextField} from './InputTextField';
import {FieldLabel} from './FieldLabel';

export const QuantitiesCard = ({initQuantityState, quantityDispatch}) => {
  return (
    <>
      <QuantitiesCardDiv>
        <CardHeader fontSize='20px' label='Est Quantities'/>
        <FieldLabel />
        <InputTextField
          id={Object.keys(initQuantityState)[0]}
          label='Minimum'
          handleChange={(event) => quantityDispatch({type: 'handleFieldChange', event})}/>
        <FieldLabel label='Qty >'/>
        <InputTextField
          id={Object.keys(initQuantityState)[1]}
          label='Price Break 1'
          handleChange={(event) => quantityDispatch({type: 'handleFieldChange', event})}/>
        <FieldLabel label='Qty >'/>
        <InputTextField
          id={Object.keys(initQuantityState)[2]}  
          label='Price Break 2'
          handleChange={(event) => quantityDispatch({type: 'handleFieldChange', event})}/>
        <FieldLabel label='Qty >'/>
        <InputTextField
          id={Object.keys(initQuantityState)[3]} 
          label='Price Break 3'
          handleChange={(event) => quantityDispatch({type: 'handleFieldChange', event})}/>
      </QuantitiesCardDiv>
    </>
  )
}

const CardHeader = styled(MinorHeader)`
  grid-area: header;
  align-self: end;
  justify-self: end;
`;

const QuantitiesCardDiv = styled.div`
  grid-area: quantities;
  padding: 10px 20px 120px 20px;
  margin: 20px 0px 20px 0px;
  display: grid;
  grid-template-areas:
    "header      header"
    "minLabel    minInput"
    "qty1Label   priceBreak1Input"
    "qty2Label   priceBreak2Input"
    "qty3Label   priceBreak3Input";
  
  grid-template-columns: 40px auto;
  grid-template-rows: auto auto auto auto;
  gap: 10px 10px;
  justify-items: left;
  align-items: left;
  border-left: 2px solid #979797;
  align-content: center;
`;