const config = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "blueprint-mockupgenerator-logo-upload",
  },
  apiGatewayPython: {
    // blueprint-mockup-api (blueprint-python-api2 in WSL)
    REGION: "us-east-1",
    URL: "https://wwr45nh8uk.execute-api.us-east-1.amazonaws.com/dev",
  },
  apiGatewayJS: {
    REGION: "us-east-1",
    URL: "https://a6plkb7pfk.execute-api.us-east-1.amazonaws.com/dev"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_XNOWDtwOR",
    APP_CLIENT_ID: "4ogalcsrqvq8m55jgllibui8gt",
    IDENTITY_POOL_ID: "us-east-1:22e886ad-2c19-436e-9fcd-9495ed484f3c",
  },
};

export default config;