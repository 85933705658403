import React from "react";
import Button from "react-bootstrap/Button";
// import { onError } from "../../libs/errorLib";
import "./InputTable.css";

export default function InputTable({addRow, changeCell, deleteRow, cells, colHeaders, rowStructure}) {

  const dataKeys = Object.keys(rowStructure);
  
  var renderTable = cells.map(function(item, index){
      return(
        <tr key={"row"+index} style={{height:"100px"}}>
          <td key={"row"+index+"col1"} id={"row"+index+"col1"}>
            <button
              key={"deleteButton"+index}
              id={"deleteButton"+index}
              onClick={deleteRow}
              data-index={index}
            >
              Delete
            </button>
          </td>
          {dataKeys.map(function(colName){  
            return (
              <td key={"row"+index+"col"+dataKeys.indexOf(colName)}
                  id={"row"+index+"col"+dataKeys.indexOf(colName)}>
                <input
                  id={colName+index}
                  key={colName+index}
                  data-id = {colName}
                  style={{width:"100%"}}
                  type="text"
                  value={item[colName]}
                  data-index={index}
                  onChange = {changeCell}
                >
                </input>
              </td>
            )})}
        </tr>
      )
    })
  
  var renderTableHeader = colHeaders.map(function(header, index){
    return (
      <th key={header}>
        {header}
      </th>
    )
  })
  
  return (
    <div className="FinancesInputTable">
      <table>
        <thead>
          <tr>
            <th/>
            {renderTableHeader}
          </tr>
        </thead>
        <tbody>
          {renderTable}
        </tbody>
      </table>
      <Button
        block
        onClick={ addRow }>
        Add Job
      </Button>

        
      
    </div>
  );
}