import React, {useState, useCallback} from 'react';
import styled from 'styled-components';
import {ContentContainer} from '../components/ContentContainer';
import {MinorHeader} from '../components/MinorHeader';
import {PricingBox} from '../components/PricingBox';
import {InputTextField} from '../components/InputTextField';



export const CreateMockup2 = () => {
  const [projectName, setProjectName] = useState('');

  const handleProjectNameChange = useCallback((e) => { 
    let newProjectName = e.target.value;
    setProjectName(newProjectName)
  }, [])

  return(
    <>
      <ContentContainer>
        <MinorHeader label='Mockup Generator'/>
        <ProjectNameInput id='projectName' label='Project Name' handleChange={handleProjectNameChange}/>
        <PricingBox projectName={projectName} contractor1='phoenixScreenprintPricing'/>
      </ContentContainer>
    </>
  )
}


const ProjectNameInput = styled(InputTextField)`
  padding: 0px 0px 10px 0px;
  min-width: 200px;
`;