import React from 'react';
import styled from 'styled-components';

export const FieldContainer = (props) => {

  return(
    <FieldContainerDiv className={props.className}>
      {props.children}
    </FieldContainerDiv>
  )
}


const FieldContainerDiv = styled.div`
  width: 146px;
  margin: 0px 0px 0px 0px;
  padding: ${props => props.padding ? props.padding : '0px 0px 0px 0px'}
`;