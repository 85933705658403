import React from 'react';
import styled from 'styled-components';

export const InputRow2 = (props) => {
  return(
    <RowContainer>
      {props.children}
    </RowContainer>
  )
}

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 20px;
`;