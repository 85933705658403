import React from 'react';
import styled from 'styled-components';

export const FieldLabel = ({label, fontSize, fontWeight, className}) => {

  return(
    <FieldLabelP 
      className={className} 
      fontSize={fontSize}
      fontWeight={fontWeight}>{label}</FieldLabelP>
  )
}

const FieldLabelP = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: ${props => props.fontWeight ? props.fontWeight : '600'};
  font-size: ${props => props.fontSize? props.fontSize : '18px'};
  color: #000000;
  letter-spacing: -0.31px;
  text-align: left;
  margin: 0px 0px 0px 0px;
`;