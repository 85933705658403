import React from 'react';
import styled from 'styled-components';
import {FieldLabel} from "./FieldLabel";
import {FieldContainer} from "./FieldContainer";
export const InputDropdown = ({label, options, handleChange, id}) => {
  return (
    <FieldContainer>
      <FieldLabel label={label}/>
      <Dropdown
        id={id}
        // defaultValue={options[0]}
        onChange={handleChange}
      >
        {options.map((option, index) => (
          <option key={option} value={option} >{option}</option>
        ))}
      </Dropdown>
    </FieldContainer>
  )
}


const Dropdown = styled.select`
  width: 100%;
  height: 30px;
  border-radius: 7px;
  border: 1px solid #979797;
`;
