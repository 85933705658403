import React from 'react';
import styled from 'styled-components';


const Title = styled.p`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: ${props => props.fontSize ? props.fontSize : '26px'};
  color: #000000;
  letter-spacing: -0.31px;
  text-align: left;
  margin: 0px 0px 0px 0px;
`;

export const MinorHeader = ({label, fontSize, className}) => {
  return (
    <Title className={className} fontSize={fontSize}>{label}</Title>
  )
}






