import { Storage } from "aws-amplify";

export async function s3Upload(projectName, file) {
  const filename = `${file.name}`;
  // Create S3 folder and upload the logo
  const stored = await Storage.put(projectName+'/'+filename, file, {
    contentType: file.type,
  });

  return stored.key;
}

export const s3LogoUpload = async (projectName, file, filename) => {
  await Storage.put(projectName+'/'+filename, file, {
    contentType: file.type,
  })
}

export async function s3getImage(S3path) {
  const result = await Storage.get(S3path)
  .then ( result => {return result})
  .catch(err => console.log(err));
  console.log(typeof(result))
  return result

}

function downloadBlob(blob, filename) {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'download';
  const clickHandler = () => {
    setTimeout(() => {
      URL.revokeObjectURL(url);
      a.removeEventListener('click', clickHandler);
    }, 150);
  };
  a.addEventListener('click', clickHandler, false);
  a.click();
  return a;
}

export const s3Download = async (projectName, fileNameOutput) => {
  const filename = projectName+'/'+fileNameOutput
  await Storage.get(filename, {download: true})
  .then(result => downloadBlob(result.Body, fileNameOutput))
  .catch(err => console.log(err));
}

