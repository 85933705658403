import React, {useState, useRef } from "react";
import Form from "react-bootstrap/Form";
import {Col} from "react-bootstrap";
// import { useHistory } from "react-router-dom";
import LoaderButton from "../components/LoaderButton";
import { onError } from "../libs/errorLib";
// import config from "../config";
import { API } from "aws-amplify";
import { s3getImage } from "../libs/awsLib";
import {Image} from 'react-bootstrap';
import { useFormFields } from "../libs/hooksLib";
import styled from 'styled-components';
import {motion} from 'framer-motion';

const Container = styled.div`
	padding: 20px;
`;
const Header = styled.h1`
	font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 36px;
  font-weight: 650;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 40px 0px 40px 6px;
`;

export default function AddMockupData() {

		const constraintsRef = useRef(null);
		const [isLoading, setIsLoading] = useState(false);
		const [fields, handleFieldChange] = useFormFields({
			brand: "",
			productCode: "",
			align: "",
			positionX: "",
			positionY: "",
			maxWidth: "",
			maxHeight: "",
			orientation: "",
			placement: ""
		});

		const [mockImg, setMockImg] = useState(null);
		const [mockExists, setMockExists] = useState(false);

		function validateForm(){
			var allTrue = Object.keys(fields).every(function(k){return fields[k]});
			return allTrue;
		}

		async function createMockupSpec() {
			const params = {
				queryStringParameters: {
					itemId: fields.brand+'-'+fields.productCode,
					imprintId: fields.placement,
					brand: fields.brand,
					productCode: fields.productCode,
					align: fields.align,
					positionX: fields.positionX,
					positionY: fields.positionY,
					maxWidth: fields.maxWidth,
					maxHeight: fields.maxHeight,
					orientation: fields.orientation,
					placement: fields.placement
				}
			}
			await API.put("blueprint-mockup-api", "/createMockupSpec", params);

		}

    async function handleSubmit(event){
			event.preventDefault();
			setIsLoading(true);

			try {
				await createMockupSpec();
				setIsLoading(false);
				// resetFields();
			} catch(e) {
				onError(e);
				setIsLoading(false);
			}
		}
		
		async function testMockup(event) {
			event.preventDefault();
			const params = {
				queryStringParameters: {
					brand: fields.brand,
					productCode: fields.productCode,
					align: fields.align,
					positionX: fields.positionX,
					positionY: fields.positionY,
					maxWidth: fields.maxWidth,
					maxHeight: fields.maxHeight,
					orientation: fields.orientation,
					placement: fields.placement
				}
			}
			setIsLoading(true);
			try {
				await API.get("blueprint-mockup-api", "/testMockupSpecs", params);
				console.log("success!");
				setIsLoading(false);
				setMockExists(true);
				const tempImg = await s3getImage('testMockup/testMockup.png');
				console.log(tempImg)
				setMockImg(tempImg);
			} catch (e) {
				onError(e);
				setIsLoading(false)
			}
		}
    return (
        <Container>
					<Header>Add Mockup Data</Header>
            <Form onSubmit={handleSubmit}>
							<Form.Row>
								<Form.Group as={Col} controlId="brand">
									<Form.Label><b>Brand</b></Form.Label>
									<Form.Control
										value={fields.brand}
										as="input"
										onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. Gildan</Form.Text>
								</Form.Group>

								<Form.Group as={Col} controlId="productCode">
									<Form.Label><b>Product Code</b></Form.Label>
									<Form.Control
										value={fields.productCode}
										as="input"
										onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. 18500</Form.Text>
								</Form.Group>
							</Form.Row>

							<Form.Group controlId="align">
								<Form.Label><b>Alignment</b></Form.Label>
								<Form.Control
									value={fields.align}
									as="input"
									onChange= {handleFieldChange}>
								</Form.Control>
								<Form.Text muted as="small">Ex. top-center</Form.Text>
							</Form.Group>

							<Form.Row>
								<Form.Group as={Col} controlId="positionX">
									<Form.Label><b>x-Coordinate</b></Form.Label>
									<Form.Control
										value={fields.positionX}
										as="input"
										onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. 302</Form.Text>
								</Form.Group>

								<Form.Group as={Col} controlId="positionY">
									<Form.Label><b>y-Coordinate</b></Form.Label>
									<Form.Control
										value={fields.positionY}
										as="input"
										onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. 547</Form.Text>
								</Form.Group>
							</Form.Row>
				
							<Form.Row>
								<Form.Group as={Col} controlId="maxWidth">
									<Form.Label><b>Max Width</b></Form.Label>
									<Form.Control
										value={fields.maxWidth}
										as="input"
										onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. 350</Form.Text>
								</Form.Group>

								<Form.Group as={Col} controlId="maxHeight">
									<Form.Label><b>Max Height</b></Form.Label>
									<Form.Control
										value={fields.maxHeight}
										as="input"
										onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. 255</Form.Text>
								</Form.Group>
							</Form.Row>

							<Form.Group controlId="orientation">
								<Form.Label><b>Orientation</b></Form.Label>
								<Form.Control
									value={fields.orientation}
									as="input"
									onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. Front</Form.Text>
							</Form.Group>

							<Form.Group controlId="placement">
								<Form.Label><b>Placement</b></Form.Label>
								<Form.Text muted>
									Use camelCase here (lowerCaseThenUpperCase)
									This is important for dataflow.
								</Form.Text>
								<Form.Control
									value={fields.placement}
									as="input"
									onChange= {handleFieldChange}>
									</Form.Control>
									<Form.Text muted as="small">Ex. fullChest</Form.Text>
							</Form.Group>
							<LoaderButton
								block
								type="submit"
								size="lg"
								variant="primary"
								isLoading={isLoading}
								disabled={!validateForm()}>
								Create Mockup Spec
							</LoaderButton>
            </Form>
						<br/>
						<LoaderButton 
							block
							type="button"
							size="lg"
							variant="primary"
							onClick={testMockup}
							isLoading={isLoading}>
							Test Mockup Placement		
						</LoaderButton>
						{ mockExists && 
						<DragContainer ref={constraintsRef}>
							<Image src={mockImg} fluid/>
							<Rectangle 
								drag 
								dragConstraints={constraintsRef}   
								onDragEnd={(event, info) => console.log(event.target.style.transform.replace(/px/gi,"").split("(",)[1].split(","), event.target.offsetLeft)}
								dragMomentum={false}
								dragElastic={0}/>
						</DragContainer> }
        </Container>
    )
}

const DragContainer = styled(motion.div)`
	height: 1250px;
	width: 1000px;
`;


const Rectangle = styled(motion.div)`
	width: 100px;
	height: 100px;
	background: green
`;