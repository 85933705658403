import React from 'react';
import styled from 'styled-components';


export const PillButton = ({label, className, onClick}) => {

  return(
    <>
      <PillButtonBtn
        className={className}
        onClick={onClick}>
        {label}
      </PillButtonBtn>
    </>
  )
}

const PillButtonBtn = styled.button`
  background: #FFFFFF;
  border: 5px solid #2C3ED2;
  border-radius: 31.5px;
  width: 80%;
  height: 62px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  color: #000000;
  letter-spacing: -0.31px;
  text-align: center;
  margin: 0px 0px 0px 0px;
  padding: 2px 0px 0px 5px;
  outline: none;

  :hover {
    background: #2C3ED2;
    border: 5px solid #2C3ED2;
    border-radius: 31.5px;
    color: #FFFFFF;
    outline: none;
  }
  :focus {
    outline: none;
  }
`;

