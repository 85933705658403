import React from 'react';
import styled from 'styled-components';

import {MinorHeader} from './MinorHeader';
import {ContractorCostCols} from './ContractorCostCols';
export const CostOutputsCard = () => {
  return(
    <>
      <CostOutputsCardDiv>
        <CardHeader label='Pricing Sheet (Under Construction)'/>
        <CostsTable>
          <ContractorCostCols
            label='Ink and Thread'
            startRow={1}
            startCol={2}/>
          <ContractorCostCols
            label='Phoenix'
            startRow={1}
            startCol={6}/>
        </CostsTable>
      </CostOutputsCardDiv>
    </>
  )
}

const CostOutputsCardDiv = styled.div`
  grid-area: costOutputs;
`;

const CardHeader = styled(MinorHeader)`
  font-size: 20px;
`;

const CostsTable = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: repeat(6, 1fr);
  grid-template-areas:
    "r1c1 r1c2 r1c3 r1c4 r1c5 r1c6 r1c7 r1c8 r1c9 r1c10 r1c11 r1c12 r1c13 r1c14 r1c15"
    "r2c1 r2c2 r2c3 r2c4 r2c5 r2c6 r2c7 r2c8 r2c9 r2c10 r2c11 r2c12 r2c13 r2c14 r2c15"
    "r3c1 r3c2 r3c3 r3c4 r3c5 r3c6 r3c7 r3c8 r3c9 r3c10 r3c11 r3c12 r3c13 r3c14 r3c15"
    "r4c1 r4c2 r4c3 r4c4 r4c5 r4c6 r4c7 r4c8 r4c9 r4c10 r4c11 r4c12 r4c13 r4c14 r4c15"
    "r5c1 r5c2 r5c3 r5c4 r5c5 r5c6 r5c7 r5c8 r5c9 r5c10 r5c11 r5c12 r5c13 r5c14 r5c15"
    "r6c1 r6c2 r6c3 r6c4 r6c5 r6c6 r6c7 r6c8 r6c9 r6c10 r6c11 r6c12 r6c13 r6c14 r6c15";
  
  column-gap: 10px;
  row-gap: 10px;
`;
