import React from 'react';
import styled from 'styled-components';
import {MinorHeader} from "./MinorHeader";
import {InputRow2} from "./InputRow2";
import {InputDropdown} from "./InputDropdown";
import {InventoryGrid} from "./InventoryGrid";
import {useGetBrandsOnMount, useGetCodesOnBrandChange, useGetColorsOnCodeChange, useGetInventoryOnColorChange} from "../hooks/inventoryHooks";


// const initFilterKeys = {
//   brand: "",
//   productCode: "",
//   color: ""
// }
const sizes = ['XS', 'S', 'M', 'L', 'XL', '2XL', '3XL', '4XL', '5XL'];
const warehouseData = [{location: 'NJ', time: '1 day'}, {location: 'GA', time: '2 day'},
                {location: 'IL', time: '3 day'}, {location: 'KS', time: '3 day'},
                {location: 'TX', time: '4 day'}, {location: 'NV', time: '4 day'},
                {location: 'DS', time: '1 week'}]

export const InventoryCard = ({filterState, filterDispatch}) => {
  // Create reducer for control of filter states
  // const [filterState, filterDispatch] = useReducer(inventoryFilterReducer, initFilterKeys)
  // Create brands state to be loaded on mount
  const brands       = useGetBrandsOnMount(filterDispatch);
  const productCodes = useGetCodesOnBrandChange(filterState.brand, filterDispatch)
  const colors       = useGetColorsOnCodeChange(filterState.brand, filterState.productCode, filterDispatch) 
  const inventory    = useGetInventoryOnColorChange(filterState.brand, filterState.productCode, filterState.color)

  return (
    <>
      <InventoryCardDiv>
        <MinorHeader fontSize='20px' label='Supply'/>
        <InputRow2>
          <InputDropdown 
            label="Brand" 
            options={brands}
            id='brand'
            handleChange={(event) => filterDispatch({type: 'handleOptionChange', event: event})}/>
          <InputDropdown
            label="Product Code"
            options={productCodes}
            id="productCode"
            handleChange={(event) => filterDispatch({type: 'handleOptionChange', event: event})}/>
          <InputDropdown
            label="Color"
            options={colors}
            id="color"
            handleChange={(event) => filterDispatch({type: "handleOptionChange", event: event})}/>
        </InputRow2>
        <InventoryGrid
          sizes={sizes}
          warehousesData={warehouseData}
          inventoryData={inventory}/>
      </InventoryCardDiv>
    </>
  )
}

const InventoryCardDiv = styled.div`
  grid-area: supply;
  padding: 10px 20px 10px 20px;
`;