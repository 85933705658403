import React, {useEffect} from "react";
import Button from "react-bootstrap/Button";
import InputTable from "../components/Finances/InputTable";
import FinanceData from "../components/Finances/FinancesData";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { useTableFields } from "../libs/hooksLib";
import styled from 'styled-components';

const Container = styled.div`
	padding: 20px;
`;
const Header = styled.h1`
	font-family: Graphik, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
   "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 36px;
  font-weight: 650;
  color:rgb(5,15,25);
  line-height: 1.3;
  margin: 0px 0px 0px 0px;
  padding: 40px 0px 40px 6px;
`;

export default function Finances() {

  // Declare general states
  const {isAuthenticated} = useAppContext();
  // const [isLoading, setIsLoading] = useState(true);

  // Declare Table Parameters
  const colHeaders = ['Customer Name', 'Job Name', 'Total Garment Cost',
                      'Production Cost', 'Amount Paid', 'Amount Outstanding'];
  const rowStructure = {
    customerId: "",
    jobId: "",
    garmentCost: "",
    productionCost: "",
    paidAmnt: "",
    unpaidAmnt: ""
  }
  // Declare "Jobs" input table
  const [jobs, handleRowAdd, handleCellChange, handleRowDelete, setJobs] = useTableFields(
        [rowStructure], rowStructure);

  // Get data from DB on load
  useEffect(() => {
    async function onLoad() {
      if(!isAuthenticated) {
        return;
      }
      try {
        const newData = await getData();
        console.log(newData);
        setJobs(newData);
      } catch(e) {
        onError(e)
      }
      // setIsLoading(false);
    }
    onLoad();
  }, [isAuthenticated, setJobs])

  // Get Data from Blueprint Finances Table
  function getData() {
    return API.get("blueprint-finances-api", "/getJobs")
  }

  async function deleteRowData(e) {
    handleRowDelete(e);
    const index = e.target.getAttribute("data-index");
    if(jobs[index]['customerId'] && jobs[index]['jobId']){
      const params = {
        queryStringParameters: {
          customerId : jobs[index]['customerId'],
          jobId      : jobs[index]['jobId'],
        }
      }
      await API.del("blueprint-finances-api", "/deleteJob", params)
    }
  }

  async function sendData() {
    const params = {
      body : {
        jobs
      }
    }
    console.log(params)
    const a = await API.put("blueprint-finances-api", "/createJob", params)
    // This block grabs the new data from the DB after sending it
    try {
      const newData = await getData();
      setJobs(newData)
    } catch(e) {
      onError(e);
    }
    console.log(a);
  }

  function testFun() {
    console.log("prop")
    console.log(jobs)
    console.log("Carry")
    console.log(jobs)
    // const [key, value] = Object.entries(rowStructure);
    // for (const[key, value] of Object.entries(rowStructure)){
    //   console.log(key)
    // }
    const dataKeys=Object.keys(rowStructure);
    console.log(dataKeys)

  }
  return (
    <Container>
      <Header>Finances</Header>
      <InputTable 
        changeCell={handleCellChange}
        addRow={handleRowAdd}
        deleteRow={deleteRowData}
        cells={jobs}
        colHeaders = {colHeaders}
        rowStructure={rowStructure}/>
      <br/>
      <Button
        block
        onClick={ sendData }>
        Save Changes
      </Button>
      <Button
        block
        onClick={testFun}>
        Test Function
      </Button>
      <br/>
      <FinanceData data={jobs}/>
    </Container>
  )
}